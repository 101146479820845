<template>
    <b-card>
        <b-row>
            <b-col cols="12">

                <b-table
                    ref="instancesTable"
                    :fields="instancesHeaders"
                    :items="instancesRows"
                    item-key="id"
                    class="elevation-1"
                    selectable
                    selected-variant=""
                    no-select-on-click
                    :hide-default-footer="true"
                    disable-pagination
                    @row-selected="onRowSelected"
                >
                <template #head(selected)>
                <div class="d-flex align-items-center">
                    <label class="checkbox checkbox-lg checkbox-outline checkbox-success">
                    <input type="checkbox" name="" :checked="allSelected" @click="toggleRows" />
                    <span></span>
                    </label>
                </div>
                </template>
                
                <template #cell(selected)="data">
                <div class="d-flex align-items-center">
                    <label class="checkbox checkbox-lg checkbox-outline checkbox-success">
                    <input
                        type="checkbox"
                        name=""
                        :checked="data.rowSelected"
                        @click="selectRow(data.index)"
                    />
                    <span></span>
                    </label>
                </div>
                </template>
                </b-table>
            </b-col>
        </b-row>
    
    </b-card>

</template>

<style scoped>
.narrow-checkbox {
  max-width: 100px;
}
</style>

<script>

import axios from 'axios';
import { mapGetters } from 'vuex';
import Confirm from '@/view/components/Confirm.vue';
import { toasts } from '@/core/mixins/toastr-helper.mixin.js';


export default {
  name: 'InstancesSelectableTable',
  components: {
    Confirm
  },
  computed: {
    
  },
  mixins: [ toasts ],
  mounted() {
    this.getInstances();
  },
  data() {
    return {
      instancesHeaders: [
        {
          thStyle: { width: '64px !important' },
          key: 'selected',
          label: 'Vald',
          sortable: false
        },
        { 
          label: 'Namn', 
          sortable: false,
          key: 'name' 
        }
      ],
      instancesRows: [
        
      ],
      allSelected: false,
      selected: []
    };
  },
  watch: {
    
  },
  methods: {
    getInstances() {
      axios 
        .get(`/ths/instance`)
        .then(res => {
          this.instancesRows = res.data;
        })
        .catch(err => {
          console.error(err);
        });
    },

    getSelection() {
      return this.selected;
    },

    onRowSelected(items) {
      this.selected = items;
    },

    selectRow(index) {
      if (this.$refs.instancesTable.isRowSelected(index)) {
        this.$refs.instancesTable.unselectRow(index);
      } else {
        this.$refs.instancesTable.selectRow(index);
      }
    },
    
    toggleRows() {
      this.allSelected = !this.allSelected;
      
      if (this.allSelected) {
        this.$refs.instancesTable.selectAllRows();
        this.instancesTable = true;
      } else {
        this.$refs.instancesTable.clearSelected();
        this.instancesTable = false;
      }
    },

  }
};
</script>
