<template>
  <div id="template-editor-modal">

    <ErrorPopup
      :error="error"
    />

    <b-modal ref="edit-template" size="xl" hide-footer>
      
      <TemplateEdit
        ref="template-editor"

        @change="changed_template"
        v-bind:value="local_template" v-on:update:template="update_template"
        @created="created_template"
      />
      
      <RightModalSaveAndCloseButtons
        :text="$t('COMMON.SAVE')"
        :spin="true"
        @clicked="submit_form"
        @close="on_cancel"
        ref="saveButton"
      />
    
    </b-modal>

  </div>
</template>

<script>
import axios from 'axios';
import { mapGetters } from 'vuex';
import { toasts } from '@/core/mixins/toastr-helper.mixin.js';

import ErrorPopup from '@/view/components/ErrorPopup.vue';
import TemplateEdit from '@/view/pages/ml/templates/TemplateEdit.vue';
import RightModalSaveAndCloseButtons from '@/view/components/buttons/RightModalSaveAndCloseButtons.vue';

export default {
  name: 'TemplateEditorModal',
  components: {
    TemplateEdit,
    ErrorPopup,
    RightModalSaveAndCloseButtons
  },
  mixins: [ toasts ],
  
  computed: {
    ...mapGetters(['currentCompanyId', 'currentPeriodId']),
    
  },

  watch: {
    item: {
      deep: true, // check properties of the form, not just the form reference (example.name for example)
      handler(val) {
        this.local_template = { ...this.item };
      }
    }
  },

  props: ['item'],
  emits: ['created', 'updated'],
  async mounted() {
    this.local_template = { ...this.item };

    console.log('mounted', this.item)
  },
  methods: {

    update_template(item) {
      this.local_template = { ...item };

      this.$emit('updated', item);
    },

    created_template(item) {
      this.local_template = { ...item };

      this.$emit('created', item);
    },

    on_cancel() {
      this.$refs['edit-template'].hide();
    },
    
    show() {
      
      this.$nextTick(()=>{
        this.$refs['edit-template'].show();  
      });
    },

    changed_template(item) {
      this.local_template = { ...item };
    },


    async submit_form() {

      this.$refs['template-editor'].saveTemplate();

      if (this.local_template.template_id) {
        this.$emit('updated', this.local_template);
      }
      else {
        this.$emit('created', this.local_template);
      }

      this.$refs['saveButton'].stop();
    }


  },
  data() {
    return {
      local_template: {},
      error: null
    };
  },
  
};
</script>
<style lang="scss" scoped>

:deep .v-treeview-node__root:hover {
  cursor: pointer;
}

.tree-panel {
  background-color: white;
}

:deep .v-simple-checkbox {
  float: left;
  width: 22px;
  margin-left: 8px;
}

:deep .v-input__slot label {
  font-size: 12px;
}

:deep .v-data-table__wrapper tr:first-child {
  height: 66px;
  background-color: rgb(239, 239, 239) !important;
}

@media (min-width: 1200px) {
  :deep .modal-dialog.modal-xl {
    max-width: 1400px;
  }
}

</style>
